import React from 'react'
import "./Conversations.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

function ConversationDetail(props) {
    const [display,setDisplay]=React.useState(false)
    const [messages,setMessages]=React.useState([])
    React.useEffect(()=>{
        let timearray = props.location.state.messages.sort(function (a, b) {
            return a.createdAt.localeCompare(b.createdAt);
        });
        setMessages(props.location.state.messages)
    },[])

    const renderName = (array1,array2)=>{
        if(array1.length>0){
            return array1[0].name
        }else if(array2.length>0){
            return array2[0].name
        }
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


                {messages.length>0&&<h1>Conversation between {props.location.state.recname} and {renderName(props.location.state.fromUser,props.location.state.toUser)} </h1>}
                <section  className="my-5 conv-cost"> 
                {messages.length>0&&messages.map((item,index)=>
                    item.sendby==="RECRUITER"?<div key={index} className="my-5">
                    <span className="sender">{item.message}</span>
                     <p className="py-4">{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    </div>:
                    <div key={index} className="my-5" style={{textAlign:"right"}}>
                    <span className="receiver">{item.message}</span>
                    <p className="py-4" >{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    </div>
                    
                    
                )}
                </section>
            



             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}
export default connect(mapStateToProps)(ConversationDetail)
