import React from 'react'
import "./Home.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from './Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import {storeUserInfo} from './redux/user/userActions'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import {useForm} from 'react-hook-form'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, TextField,Alert } from '@mui/material';
import Chip from '@mui/material/Chip';
import S3FileUpload from 'react-s3'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import {setLoading} from './redux/loading/loadingActions'

const Input = styled('input')({
  display: 'none',
});
function Home(props) {
    const [display,setDisplay]=React.useState(false)
    const [company,setCompany]=React.useState(null)
    const [edit,setEdit]=React.useState(false)
    const [singleDomain,setSingleDomain]=React.useState("")
    const [domains,setDomains]=React.useState([])
    const {register,handleSubmit,formState:{errors},setValue}=useForm()
    const [flag,setFlag]=React.useState(false)
    const [companyName,setCompanyName]=React.useState("")
    const [companyBrief,setCompanyBrief]=React.useState('')
    const [error,setError]=React.useState("")

    

    React.useEffect(()=>{
        props.setLoading(true)
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCompany`,{headers:{token:props.user.user}})
        .then(res=>{
            setCompany(res.data.result)
            setCompanyName(res.data.result.companName)
            setCompanyBrief(res.data.result.companyBrief)
            setDomains(res.data.result.domains)
            axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getSingleRecruiter`,{headers:{token:props.user.user}})
            .then(response=>{
                props.storeUserInfo(response.data.result)
                props.setLoading(false)
            })
        })
    },[flag])

    const upload = (e)=>{
        S3FileUpload.uploadFile(e.target.files[0],
        {
                bucketName: 'hashjobbucket1',
                dirName: `staging/companies/${company.companyName}/assets`, /* optional */
                region: 'ap-south-1',
                accessKeyId: 'AKIA4WSAA3XHBVE2TCGC',
                secretAccessKey: '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',
                
          }    
        )
        .then((data)=>{
            //data.location has loc
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editCompanyLogo`,{companyLogo:data.location,id:company._id},{headers:{token:props.user.user}})
            .then(res=>{
                setFlag(!flag)
            })
        })
        .catch(err=>{
        })
    }

    const onSubmit = ()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/editCompany`,
        {id:company._id,
            object:{
                domains,
                companyName,
                companyBrief
            }
        },
        {headers:{token:props.user.user}})
        .then(res=>{
            setFlag(!flag)
            setEdit(false)
        })
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={1} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

            {
                company&&<div className="shadow company-div">
                    <h1>Company Info <IconButton onClick={()=>setEdit(!edit)}><EditIcon /></IconButton></h1>
                    {!edit?
                    <div>
                    <img src={company.companyLogo} alt="company Logo" />
                    <div className="mt-4 mb-2" style={{textAlign:"center"}}>
                    <label htmlFor="contained-button-file">
                    <Input 
                    onChange={upload}
                    accept="image/*" id="contained-button-file" multiple type="file" />
                    <Button variant="contained" component="span" endIcon={<CameraAltIcon />}>
                    Change image
                    </Button>
                    </label>
                    </div>
                    
                    <p>Company Name: <b>{company.companyName}</b></p>
                    <p>Company Brief: <b>{company.companyBrief}</b></p>
                    <p>Company brief: <b>{company.companyBrief}</b></p>
                    
                    <p>Company Domains: {company.domains.map((item,index)=><span className='ml-2' key={index}><b>{item}</b></span>)}</p>
                    
                    {/* <img src={company.companyLogo} alt="company Logo" />
                    <br /> */}
                    {company&&<>
                    <h2>Company Package Info</h2>
                    {
                        company.package?.package?.name 
                        ? <p>This company is currently on package {company.package?.package?.name}</p>
                        : <></>
                    }
                    <p>Activated on : {moment(company.package.activatedon).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    </>}
                    </div>:
                    <div className="edit-div">
                        <form onSubmit = {handleSubmit(onSubmit)}> 
                        <TextField value={companyName} onChange={(e)=>setCompanyName(e.target.value)} disabled className="my-3" fullWidth label="Company Name" />
                        <TextField value={companyBrief} onChange={(e)=>setCompanyBrief(e.target.value)} className="my-3" fullWidth  label="Company Brief" />
                        <div className="row my-3 mx-auto align-items-center">
                            <div className="col-10">
                            <TextField fullWidth onChange={(e)=>setSingleDomain(e.target.value)} label="Company Domain" />
                            </div>

                            <div className="col-2">
                                <IconButton onClick={()=>{
                                    if(!domains.includes(singleDomain) && /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(singleDomain)){
                                        setDomains([...domains,singleDomain])
                                        setError("")
                                    }else{
                                        setError("Enter a valid domain")
                                    }
                                }}>
                                    <AddCircleIcon sx={{fontSize:25}}  />
                                </IconButton>
                            </div>
                        </div>

                        {
                            domains.map((item,index)=><Chip className="m-3" key={index} label={item} onDelete={()=>setDomains(domains.filter(i=>i!==item))} />)
                        }

                        <div style={{textAlign:"center"}}>
                        {error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
                        </div>
                        <div>
                        <Button onClick={()=>onSubmit()} variant="contained">Submit</Button>
                        </div>
                        </form>
                    </div>
                    }
                    
                    </div>
            }


            {/* <input type="file" onChange={upload} /> */}




             </div>
    </div>
    )
}

const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}

const mapDispatchToProps = (dispatch)=>{    
    return {
        storeUserInfo:(userInfo)=>dispatch(storeUserInfo(userInfo)),
        setLoading:value=>dispatch(setLoading(value))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)
