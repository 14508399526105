import React from 'react'
import "./Dashhead.scss"
import {withRouter} from 'react-router'
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {connect} from 'react-redux'
import {Button} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import {deleteUser} from './redux/user/userActions'
const Dashhead = (props) => {
    let {id,display} = props
    return (
        
            
        <div className={display?"shadow-lg dashhead":'dashhead displayhidden'}>
            <div className="py-4" style={{textAlign:"center"}}>
            <img src="/logo.png" alt="logo" />
            </div>
            {id===1?<div className="menu-container-active" onClick={()=>props.history.push('company')}>
                <p><HomeIcon /> Company</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('company')} >
            <p><HomeOutlinedIcon /> Company</p>
            </div>
            }

            {id===2?<div className="menu-container-active" onClick={()=>props.history.push('team')}>
                <p><NoteAddIcon /> Team</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('team')}>
            <p><NoteAddOutlinedIcon /> Team</p>
            </div>
            }

            {id===3?<div className="menu-container-active" onClick={()=>props.history.push('conversations')}>
                <p><EventAvailableIcon /> Conversation</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('conversations')} >
            <p><EventAvailableOutlinedIcon /> Conversation</p>
            </div>
            }

            {id===4?<div className="menu-container-active" onClick={()=>props.history.push('subscriptions')}>
                <p><LocalAtmIcon /> Subscription</p>
            </div>:
            <div className="menu-container" onClick={()=>props.history.push('subscriptions')}>
            <p><LocalAtmIcon /> Subscription</p>
            </div>
            }

<div onClick={()=>{
                props.deleteUser();
                props.history.push("/")
            }} className="mt-3" style={{textAlign:"center"}}>
                <Button startIcon={<LogoutIcon />} variant="contained" color="secondary">Logout</Button>
            </div>
            
        </div>
    );
}

const mapStateToProps = ({EventUser})=>{
    return {
        user:EventUser
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        deleteUser:()=>dispatch(deleteUser())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashhead));