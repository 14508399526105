import React from 'react'
import "./Subscriptions.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {connect} from 'react-redux'
import Dashhead from '../Dashhead';
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import {Button} from '@mui/material'
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import date from 'date-and-time';
import {Link} from 'react-router-dom'
import {setLoading} from '../redux/loading/loadingActions'
function Subscriptions(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData] = React.useState(null)
    const [plans,setPlan]=React.useState(null)
    //const [selectedId,setSelectedId]=React.useState("")
    const [selectedPlan,setSelectedPlan]=React.useState("")
    const [flag,setFlag]=React.useState(false)
    const [company,setCompany]=React.useState(null)
    const [transactions,setTransactions]=React.useState([])
    const [disabledIndex,setDisabledIndex]=React.useState(0)
    React.useEffect(()=>{
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
        props.setLoading(true)
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCompany`,{headers:{token:props.user.user}})
        .then(res=>{
            props.setLoading(false)
            setCompany(res.data.result)
            let arr = res.data.result.transactions.map((item,index)=>({...item,id:index+1}))
            setTransactions(arr)
            axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllPlans`)
                .then(response=>{
                    let arr2 = response.data.result.map((item,index)=>({...item,id:index+1}))
                    setPlan(arr2)
                    if(response.data.result.length>0){
                        setSelectedPlan(response.data.result[response.data.result.length-1]._id)
                    }
                })
        })
        .catch(err=>{
            props.setLoading(false)
        })
        
    },[flag])


    const openPayModal = () => {
        let pl = plans.filter(p=>p._id===selectedPlan)[0]
        let today = new Date(Date.now());
        let activatedon = new Date(company.package.activatedon)
        let subDays = Math.ceil(date.subtract(today, activatedon).toDays()); 
        let totalDays
        let percantageofactivedays
        let totalAmount=1
        if(pl.frequency==="Yearly"){
            totalDays = 365 - subDays; // 365 days amount is pl.amount 
            percantageofactivedays = (totalDays/365)*100
            totalAmount = Math.ceil((pl.price/100)*percantageofactivedays)
        }else if(pl.frequency==="Monthly"){
            totalDays = 30 - subDays; // 365 days amount is pl.amount 
            percantageofactivedays = (totalDays/30)*100
            totalAmount = Math.ceil((pl.price/100)*percantageofactivedays)
        }else{
            totalDays = 90 - subDays; // 365 days amount is pl.amount 
            percantageofactivedays = (totalDays/90)*100
            totalAmount = Math.ceil((pl.price/100)*percantageofactivedays)
        }
        const options = {
          key: 'rzp_test_qhwZasXmWTDQhG',
          amount: `${totalAmount*100}`, //  = INR 1
          name: 'Hashjobs',
          description: 'some description',
          image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
          handler: function(response) {
              axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/updateCompanyPlan`,
              {
                  "companyId":company._id,
                  "planId":selectedPlan,
                  "transactionId":response
              },{headers:{token:props.user.user}})
              .then(res=>{
                  setFlag(!flag)
              })
          },
          prefill: {
              name: 'Gaurav',
              contact: '9999999999',
              email: 'demo@demo.com'
          },
          notes: {
              address: 'some address'
          },
          theme: {
              color: 'blue',
              hide_topbar: false
          }
      };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
      };



    const getPlanName = (packageId)=>{
        if(plans){
            let f = plans.filter(i=>i._id===packageId)
            if(f.length>0){
                return f[0].name
            }else{
                return ""
            }
        }
    }

    const getPlanFrequency = (packageId)=>{
        if(plans){
            let f = plans.filter(i=>i._id===packageId)
            if(f.length>0){
                return f[0].frequency
            }else{
                return ""
            }
        }
    }

    const columns2 = [
        { field: 'id', headerName: 'ID',width:20},
        { field: 'transactionId', headerName: 'TransactionId',valueGetter:(param)=>param.row.transactionId,width:200},
        { field: 'name', headerName: 'Name',valueGetter:(param)=>getPlanName(param.row.package),width:150},
        {field:"activatedon",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120},
        {field:"expiry",headerName:"Expiry",valueGetter:(param)=>{
            let planFrequency = getPlanFrequency(param.row.package)
            let activatedon = new Date(param.row.activatedon)
            if(planFrequency==="Yearly"){
            return moment.parseZone(date.addDays(activatedon, 365)).local().format("DD/MM/YY")
            }else if(planFrequency === "Monthly"){
            return moment.parseZone(date.addDays(activatedon, 30)).local().format("DD/MM/YY")
            }else{
            return moment.parseZone(date.addDays(activatedon, 90)).local().format("DD/MM/YY")
            }
            
        },width:120},
        {field:"Invoice",headerName:"Invoice",
        renderCell: (params) => 
        <a href="">View Invoice</a>
        ,width:150},

      ];


    const renderDisabled = (index)=>{

        if(index<=company.package.package.index){
            return true;
        }else{
            return false;
        }
        // let activePlan = plans.filter(item=>item._id===company.package.package._id)[0]
        // if(ind<=activePlan.id){
        //     return true
        // }else{
        //     return false
        // }
    }

    const renderName = ()=>{
        let singleplan = plans.filter(i=>i._id===company.package.package._id)[0]
        return singleplan.name + singleplan.frequency
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={4} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

             {
                (company&&plans)&&<h1>Company is currently on plan {renderName()}</h1>
             }

            {
                plans&&<FormControl className="m-3">
      <FormLabel id="demo-row-radio-buttons-group-label">Plan</FormLabel>
      <RadioGroup
        onChange={(e)=>setSelectedPlan(e.target.value)}
        value={selectedPlan}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {plans.map((item,index)=><FormControlLabel disabled={renderDisabled(item.index)} key={index} value={item._id} control={<Radio />} label={`${item.name} ${item.frequency}`} />)}
      </RadioGroup>
    </FormControl>
    }
            
            <div>
            <Button onClick={()=>openPayModal()} variant="contained">Change company Plan to {
                selectedPlan.length>0&&plans.filter(i=>i._id===selectedPlan)[0].name
            }</Button>
            </div>

            <h1>Previous Transactions</h1>

            <div className="my-4" style={{ height: '40vh', width: '90%' }}>
                <DataGrid
                    rows={transactions}
                    columns={columns2}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    pagination
                    onRowClick={(item,ev)=>props.history.push('/invoice',{detail:item.row,plans})}
                />
            </div>

            <h1>Current Plans</h1>

            {
                plans&&<section className='plans row m-auto'>
                    {
                        plans.map((item,index)=><div key={index} className="plan-cont col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p>Name: <b>{item.name}</b></p>
                            <p>Price: <b>{item.price}</b></p>
                            <p>Status: <b>{item.status.toString()}</b></p>
                            <p>Active From: <b>{item.activefrom}</b></p>
                            <p>Active To: <b>{item.activeto}</b></p>
                            <p>Created At: <b>{item.createdAt}</b></p>
                            {
                                item.description.map((d,ind)=><p key={ind}>Feature {ind+1}: <b>{d}</b></p>)
                            }
                        </div>)
                    }
                </section>
            }

            {/* {
                props.user.userInfo&&<section>
                    <h3>{props.user.userInfo.package.package.name}</h3>
                    <p>Activated On: </p>
                </section>
            } */}



             </div>
    </div>
    )

}



const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}
const mapDispatchToProps = (dispatch)=>{    
    return {
        setLoading:value=>dispatch(setLoading(value))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Subscriptions)
