import './App.scss';
import {Switch,Route}  from 'react-router-dom'
import Home from "./components/Home"
import ClientAdminLogin from './components/Auth/ClientAdminLogin';
import Team from './components/Team/Team'
import Conversations from './components/Conversations/Conversations';
import Subscriptions from './components/Subscriptions/Subscriptions';
import VerifyOtp from './components/Auth/VerifyOtp';
import ConversationDetail from './components/Conversations/ConversationDetail';
import ConversationSelector from './components/Conversations/ConversationSelector';
import Invoice from './components/Invoice';
import SimpleBackdrop from './SimpleBackdrop';
import {connect} from 'react-redux'
function App(props) {

  
  return (
    <>
    <SimpleBackdrop open={props.loading} />
    <Switch>
      <Route exact path="/" component={ClientAdminLogin} />
      <Route path="/company" component={Home} />
      <Route path="/team" component={Team} />
      <Route path="/conversations" component={Conversations} />
      <Route path="/conversationselector" component={ConversationSelector} />
      <Route path="/conversationdetail" component={ConversationDetail} />
      <Route path="/subscriptions" component={Subscriptions} />
      <Route path="/verifyotp" component={VerifyOtp} />
      <Route pathh="/invoice" component={Invoice} />
    </Switch>
    </>
  );
}

const mapStateToProps = ({loading,cleverpeUser})=>{
  return {
    loading,
    user:cleverpeUser
  }
}

export default connect(mapStateToProps)(App);
