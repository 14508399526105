import React from 'react'
import "./Conversations.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import {Button, TextField} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
function ConversationSelector(props) {
    const [recruiterId,setRecruiterId]=React.useState("")
    const [display,setDisplay]=React.useState(false)
    const [conversations,setConversations]=React.useState([])
    const [conversations2,setConversations2] = React.useState([])
    const [searchCandidate,setSearchCandidate]=React.useState("")
    const [selectedConversation,setSelectedConversation]=React.useState(null)
    const [chats,setChats]=React.useState([])
    const [messageType,setMessageType]=React.useState("ALL")

    React.useEffect(()=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getRecruitersConversations`,{recruiterId:props.location.state._id},{headers:{token:props.user.user}})
        .then(res=>{
            if(res.data.result.candidateId.length > 0){
                setRecruiterId(res.data.result.recruiterId)
                setSelectedConversation(res.data.result.candidateId[0])
                setConversations(res.data.result.candidateId)
                getLoadChats(res.data.result.recruiterId, res.data.result.candidateId[0]._id)
            }
        })
    },[])

    const getLoadChats = (recruiterId, candidateId)=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getChats`,{recruiterId, candidateId},{headers:{token:props.user.user}})
        .then(res=>{
            setChats(res.data.result)
        })
    }

    const getChats = (candidateId)=>{
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getChats`,{recruiterId, candidateId},{headers:{token:props.user.user}})
        .then(res=>{
            setChats(res.data.result)
        })
    }

    const getName = ()=>{

        if(selectedConversation){
            return selectedConversation.name
        }
    }

    const renderSingleMessage = (message_type,message)=>{
        if(message_type==="IMAGE"){
            return <img className="convoimage" src={process.env.REACT_APP_S3 + message} alt="Image" />
        }else if(message_type==="TEXT"){
            return message
        }
    }

    const renderFilter = ()=>{
        if(searchCandidate.length>0){
             if(conversations2.length>0){
                return conversations2.map((item,index)=>(
                    <div key={index} className={`conversation-card row m-auto align-items-center ${selectedConversation._id===item._id&&'active'}`}>
                    <div className="col-2">
                        <img src={item.img} alt="candidateId" />
                    </div>
                    <div onClick={()=>{
                        setSelectedConversation(item)
                        getChats(item._id, item)}} className="col-10">
                        <h4>{item.name}</h4>
                        <p>{moment.parseZone(item.lastOnline).local().format("DD/MM/YY - hh:mm A")}</p>
                    </div>
                </div>
                ))
            }else{
                return <p>Conversation Not Found</p>
            }
        }
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            {conversations.length<=0?<p className="my-3" style={{color:"red",fontWeight:"bold"}}>No Conversations Available</p>:<>
            <section className="row m-auto conversation-head">
                <div className="col-4 conversation-left">
                    <TextField onChange={(e)=>{
                        let arr = conversations.filter(i=>i.name.toLowerCase().replace(" ","")===e.target.value.toLowerCase().replace(" ",""))
                         setSearchCandidate(e.target.value)
                         setConversations2(arr)
                    }} fullWidth className='my-3' variant="outlined" id="outlined-basic" label="Search Candidate" />


                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Filter Messages</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e)=>{
                            if(e.target.value==="ALL"){
                                getChats(selectedConversation._id)
                            }else{
                                setChats(chats.filter(i=>i.message_type===e.target.value))    
                            }
                            setMessageType(e.target.value)
                        }}
                        value={messageType}
                        defaultValue="ALL"
                        
                    >
                        <FormControlLabel value="ALL" control={<Radio  />} label="ALL" />
                        <FormControlLabel value="TEXT" control={<Radio />} label="TEXT" />
                        <FormControlLabel value="IMAGE" control={<Radio />} label="IMAGE" />
                        <FormControlLabel value="VIDEO" control={<Radio />} label="VIDEO" />
                        
                    </RadioGroup>
                    </FormControl>
                    
                    <hr />

                    {
                        renderFilter()
                    }

                    {
                    ((conversations.length>0&&conversations2.length<=0)?conversations.map((item,index)=>(
                        <div key={index} className={`conversation-card row m-auto align-items-center ${selectedConversation._id===item._id&&'active'}`}>
                        <div className="col-2">
                            <img src={item.img} alt="candidateId" />
                        </div>
                        <div onClick={()=>{
                            setSelectedConversation(item)
                            getChats(item._id)}} className="col-10">
                            <h4>{item.name}</h4>
                            <p>{moment.parseZone(item.lastOnline).local().format("DD/MM/YY - hh:mm A")}</p>
                        </div>
                    </div>))
                    :
                    <p></p>
                    )
                    }
                </div>

                <div className="col-8 conversation-right">
                    <h1>{getName()}</h1>
                    {
                        chats.length>0?chats.map((item,index)=>item.sendby==="CANDIDATE"?
                        <div key={index} style={{textAlign:"right"}}>
                        <span className="receiver">{renderSingleMessage(item.message_type,item.message)}</span>
                        </div>
                        :
                        <div key={index}>
                        <span className="sender">{renderSingleMessage(item.message_type,item.message)}</span>
                        </div>
                        ):<p className="my-3" style={{color:"red",fontWeight:"bold"}}>No Messages Available</p>
                    }
                    

                    
                    






                </div>

            </section>


            </>}


             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}


export default connect(mapStateToProps)(ConversationSelector)



// import React from 'react'
// import "./Conversations.scss"
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import Dashhead from '../Dashhead';
// import axios from 'axios'
// import {connect} from 'react-redux'
// import _ from 'lodash'
// import moment from 'moment';
// import { DataGrid } from '@mui/x-data-grid';
// import {Button} from '@mui/material'
// function ConversationSelector(props) {
//     const [display,setDisplay]=React.useState(false)
//     const [data,setData]=React.useState([])
//     console.log("converstaion selector",props)
//     const [conversations,setConversations]=React.useState([])
//     let newarray =[]
//     React.useEffect(()=>{
//         axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getALLConversations`,{recruiterId:props.location.state._id},{headers:{token:props.user.user}})
//         .then(res=>{
//             console.log(res)
//             //iterate over the array twice to check if to and from are similar to the swap of from and to i.e.e gather two sided messages
//             for(let i =0;i<=res.data.length;i++){
//                 let set1 = res.data[i];
//                 for(let j=res.data.length-1;j>i;j--){
//                     let set2 = res.data[j]
//                     //swap check
//                     if(set1._id.from === set2._id.to && set1._id.to === set2._id.from){
//                         console.log("same",set1)
//                         let newMessageArray= [...set1.messages,...set2.messages]
//                         let newObj = {to:set1._id.to,from:set1._id.from,fromUser:set1._id.fromUser,toUser:set1._id.toUser,messages:newMessageArray}
//                         newarray.push(newObj)
//                     }
//                 }
//                 // if(set1){
//                 //     if(newarray.length<=0 || newarray[newarray.length-1].to !== set1._id.to || newarray[newarray.length-1].to !== set1._id.from){ //newarray.filter(item=>item.to !== set1._id.to || item.to!==set1._id.from).length>0
//                 //         newarray.push({to:set1._id.to,from:set1._id.from,newMessageArray:set1.messages})
//                 //     }
//                 // }
                
//             }
//             //create arrray of ids to check if just recruiter has created the conv

//             let arrayofid = []
//             newarray.map(item=>{
//                 arrayofid.push(item.to)
//                 arrayofid.push(item.from)
//             })
//             console.log(arrayofid)

//             //store the messages of just recruiter where conv happened just one sided
//             res.data.map(i=>{
//                 if(!arrayofid.includes(i._id.to) || !arrayofid.includes(i._id.from)){
//                     newarray.push(i)
//                 }
//             })
//             console.log(newarray)

//             //filter the data because to and from is in _id
//             setConversations(newarray.map(item=>{
//                 if(item._id){
//                     return {...item._id,messages:item.messages}
//                 }else{
//                     return item
//                 }
//             }))

//             //setConversations(newarray)

//         })
//     },[])

//     console.log("converstaions is",conversations)
//     return (
//         <div className="row">
//             <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
//             <Dashhead id={3} display={display} />
//             </div>

//             <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
//             <span className="iconbutton display-mobile">
//             <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
//             <MenuIcon fontSize="inherit" />
//              </IconButton>
//              </span>


//             <h1>Select Converstaion to View Messages</h1>

//             <table class="ui celled table">
//             <thead>
//                 <tr><th>Recruiter Name</th>
//                 <th>Candidate Name</th>
//                 <th>Candidate Id</th>
//                 <th></th>
//             </tr></thead>
//             <tbody>
//                 {conversations.length>0&&conversations.map((item,index)=><tr key={index}>
//                 <td data-label="Name">{props.location.state.name}</td>
//                 <td data-label="Age">{_.map(item,(value,key)=>{
//                     if(key==="fromUser" || key==="toUser"){
//                         if(value.length>0){
//                             return value[0].name
//                         }
//                     }
//                 })}</td>
//                 <td data-label="Job">
//                 {_.map(item,(value,key)=>{
//                     if(key==="fromUser" || key==="toUser"){
//                         if(value.length>0){
//                             return value[0]._id
//                         }
//                     }
//                 })}
//                 </td>
//                 <td><Button onClick={()=>props.history.push("/conversationdetail",{...item,recname:props.location.state.name})}>View Chats</Button></td>
//                 </tr>)}
                
//             </tbody>
//             </table>




//              </div>
//     </div>
//     )
// }
// const mapStateToProps = ({EventUser})=>{    
//     return {
//         user:EventUser
//     }
// }


// export default connect(mapStateToProps)(ConversationSelector)
