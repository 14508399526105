import React from 'react'
import { Link } from 'react-router-dom'
import "./Footer.scss"

function Footer() {
  return (
    <div className="row m-auto footer align-items-center">
        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            {/* logo */}
            <img src="/joblogo.png" alt="joblogo" />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 link-cont">
            <Link className='link' to="contact">Contact</Link>
            <Link className='link' to="about">About</Link>
            <Link className='link' to="about">Terms and Condition</Link>            
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 para-cont">
            <p>2020. All Rights Reserved. Design by #job</p>
        </div>
    </div>
  )
}

export default Footer