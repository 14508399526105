import React from 'react'
import "./Home.scss"
import moment from 'moment';
function Invoice(props) {
    const detail = props.location.state.detail;
    const plans = props.location.state.plans;
    let singlePlan = plans.filter(i=>i._id===detail.package)[0]

    React.useEffect(()=>{
        setTimeout(() => {
            window.print()    
        }, 1000);
        
    },[])

    const getPlanName = (packageId)=>{
        if(plans){
            let f = plans.filter(i=>i._id===packageId)
            if(f.length>0){
                return f[0].name
            }else{
                return ""
            }
        }
    }
    var num = "zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" ");
var tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ");
    function number2words(n){
        if (n < 20) return num[n];
        var digit = n%10;
        if (n < 100) return tens[~~(n/10)-2] + (digit? "-" + num[digit]: "");
        if (n < 1000) return num[~~(n/100)] +" hundred" + (n%100 == 0? "": " and " + number2words(n%100));
        return number2words(~~(n/1000)) + " thousand" + (n%1000 != 0? " " + number2words(n%1000): "");
    }
  return (
    <div className='invoice'>
        <div className="row m-auto">
            <div className="col-3">
                <img src="/logofinal.png" alt="logo" />
            </div>
            <div className="col-5">
                <h4>0101 Digit All Solutions Pvt Ltd</h4>
                <p>Desk. No. 38, Plot #128/P2, EPIP Industrial Area</p>
                <p>Whitefield,</p>
                <p>Sonnenahalli Village</p>
                <p>Bangalore Karnataka 560066</p>
                <p>India</p>
                <p>GSTIN 29AABCZ8968L1Z8</p>
            </div>
            <div className="col-4">
                <h1>TAX INVOICE</h1>
            </div>
        </div>

        <hr />

        <section className="row m-auto align-items-start">
            <div className="col-6 row m-auto">
                <div className="col-6">
                    <p>#</p>
                    <p>Invoice Date</p>
                    <p>Terms</p>
                    <p>Due Date</p>
                </div>
                <div className="col-6">
                    <p><b>: {detail.transactionId}</b></p>
                    <p><b>: {moment.parseZone(detail.activatedon).local().format("DD/MM/YY")}</b></p>
                    <p>Due on Receipt</p>
                    <p><b>: {moment.parseZone(detail.activatedon).local().format("DD/MM/YY")}</b></p>
                </div>
            </div>
            <div className="col-6 row m-auto">
                <div className="col-6">
                    <p>Place of Supply</p>
                </div>
                <div className="col-6">
                    <p><b>: Karnataka (29)</b></p>
                </div>
            </div>
        </section>



        <table className="ui celled table">
        <thead>
            <tr>
            <th >Bill To</th>
            <th>Ship To</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td data-label="Name">101 Digital</td>
            <td data-label="Name">101 Digital</td>
            </tr>
        </tbody>
        </table>

        <section className="m-3">
            <p>Subject: </p>
            <p>Data Scrapping Service</p>
        </section>

        <table className="ui celled table">
        <thead>
            <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan={2}>CGST</th>
            <th colSpan={2}>SGST</th>
            <th>Amount</th>
            </tr>

            <tr>
            <th>#</th>
            <th>Item & Description</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>%</th>
            <th>Amt</th>
            <th>%</th>
            <th>Amt</th>
            <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>HashJob Subscription {singlePlan.name} Plan</td>
            <td>1.00</td>
            <td>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*18)}.00</td>
            <td>9%</td>
            <td>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*9)}.00</td>
            <td>9%</td>
            <td>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*9)}.00</td>
            <td>{singlePlan.price}.00</td>
            </tr>
        </tbody>
        </table>

        <section className="row m-auto ">
            <div className="col-7">
                <p>Total In Words</p>
                <p><b><i>Indian Rupee {number2words(singlePlan.price-120)}</i></b></p>
                <p className="my-5">Thanks for your business.</p>
                <p>
                    0101 DIGIT ALL SOLUTIONS PRIVATE LIMITED<br />
                    AC NUMBER: 10085601918<br />
                    ENTITY UCICI: 5872460980<br />
                    IFSC CODE: IDFB0080152<br />
                    SWIFT CODE: IDFBINBBMUM<br />
                    BANK-IDFC FIRST BANK LIMITED<br />
                    BANK ADDRESS: KORAMANGALA BRANCH<br />
                </p>
            </div>

            <div className="col-5 row m-auto total-div align-items-center">
                <div className="col-6">
                    <p>Sub Total</p>
                    <p>CGST9 (9%)</p>
                    <p>SGST9 (9%)</p>
                    <p>Amount Withheld (Section 194 C)</p>
                    <p><b>Total</b></p>
                    <p><b>Balance Due</b></p>
                </div>
                <div className="col-6">
                    <p>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*18)}.00</p>
                    <p>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*9)}.00</p>
                    <p>{Math.ceil((singlePlan.price)-(singlePlan.price/100)*9)}.00</p>
                    <p style={{color:"red"}}>(-) 120.00</p>
                    <p><b>₹{singlePlan.price-120}.00</b></p>
                    <p><b>₹{singlePlan.price-120}</b></p>
                </div>
                <div className="col-12" style={{textAlign:"center"}}>
                    <hr />
                    <p>Finance</p>
                    <p className="my-5">Authorized Signature</p>
                </div>
            </div>
        </section>



    </div>
  )
}

export default Invoice