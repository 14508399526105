import React from 'react'
import "./Auth.scss"
import { TextField,Button,Alert } from '@mui/material'
import {useForm} from 'react-hook-form'
import axios from 'axios'
import {connect} from 'react-redux'
import {setUser} from '../redux/user/userActions'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import {setLoading} from '../redux/loading/loadingActions'
function VerifyOtp(props) {
  const {handleSubmit,register,formState:{errors}}=useForm()
  const [error,setError]=React.useState("")
  const onSubmit = (data)=>{
    props.setLoading(true)
      setError("")
      axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/VerifyOtp`,{...props.location.state,otp:parseInt(data.otp)})
      .then(res=>{
        props.setLoading(false)
        props.setUser(res.data.token)
        props.history.push("/company")
      })
      .catch(err=>{
        props.setLoading(false)
        if(err.response){
            setError(err.response.data)
        }else{
            setError("Check your internet connection")
        }
        
      })

  }

  return (
    <div>
      <Header id="4" />
      <div className="row m-auto row-head-cont">
            <div className="start-div col-1 p-0">
              {/* img ellipse */}
              <img src="/ellipse.png" alt="ellipse" />
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 center-img">
              {/* imgpune */}
              <img className="heading-img" src="/heading.png" alt="heading" />
              <form onSubmit = {handleSubmit(onSubmit)}>
              <input placeholder="Enter OTP"  {...register("otp",{required:true})}/>
              {error.length>0&&<p style={{textAlign:"center",color:"red"}} className="mb-3">{error}</p>}
              <button type="submit">verify OTP</button>
              </form>
            </div>
            <div className="col-6 p-0" style={{textAlign:"right"}}>
              <img className="rightimg" src="rightimg.png" alt="rightimg" />
            </div>
        </div>
        <Footer />
    </div>
  )
}

const mapDispatchToProps = (dispatch)=>{
return {
  setUser:(user)=>dispatch(setUser(user)),
  setLoading:value=>dispatch(setLoading(value))
}
}

export default connect(null,mapDispatchToProps)(VerifyOtp)


{/* <div className="auth-container">
<div className="my-5">
<img src="/logo.png" alt="hashjobs" className="logo" />
</div>
<h3>Employer Login</h3>
<h6>SignIn buy entering the information below</h6>
<form onSubmit={handleSubmit(onSubmit)}>
<TextField error={errors.mobile?true:false} {...register("mobile",{required:true,valueAsNumber:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="Mobile Number" />
<TextField error={errors.otp?true:false} {...register("otp",{required:true})} className="textfield" fullWidth variant="outlined" id="outlined-basic" label="OTP" />
{error.length>0&&<Alert className="alert" severity="error">{error}</Alert>}
<Button type="submit" className="mt-4" fullWidth variant="contained">Signin</Button>
</form>
</div> */}