import React from 'react'
import "./Team.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import { DataGrid } from '@mui/x-data-grid';
import {Button} from '@mui/material'
import moment from 'moment';
import Alert from '@mui/material/Alert';
import {setLoading} from '../redux/loading/loadingActions'
function Team(props) {
    const [display,setDisplay]=React.useState(false)
    const [data,setData]=React.useState([])
    const [selectedId,setSelectedId]=React.useState([])
    const [flag,setFlag]=React.useState(false)
    const [company,setCompany]=React.useState(null)
    const [unblockedUsers,setUnblockedUsers]=React.useState([])
    const [error,setError]=React.useState(null)

    React.useEffect(()=>{
        setError(null)
        props.setLoading(true)
        axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCompany`,{headers:{token:props.user.user}})
        .then(response=>{
            setCompany(response.data.result)
            axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCompanyRecruiters`,{companyId:response.data.result._id},{headers:{token:props.user.user}})
            .then(res=>{
                props.setLoading(false)
                let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
                setUnblockedUsers(res.data.result.filter(i=>i.blocked===false))
                setData(arr)
            })
        })
        
    },[flag])

    

    const handleBlock = (blocked)=>{   
                 
        if(!error){
            let ubusers = unblockedUsers.filter(i=>{
                if(i.isAdmin===true){
                    return i
                }
            })
            if(selectedId.includes(props.user.userInfo._id)){
                //self block not allowed
                setError("Self Block not allowed")
            }else if(ubusers.length<1 && blocked===true){
                setError("There should be atleast one admin present")
            }
            else{
                
                if(blocked===true){
                    axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/changeBlockStatusRecruiter`,{
                        recruitersId:selectedId,
                        blocked
                    },{headers:{token:props.user.user}})
                    .then(res=>{
                        setFlag(!flag)
                    })
                }else{
                    if(unblockedUsers.length>=company.package.package.no_of_users){
                        setError("You have exceeded your current plan please upgrade to unblock more users")
                    }else{
                        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/changeBlockStatusRecruiter`,{
                            recruitersId:selectedId,
                            blocked
                        },{headers:{token:props.user.user}})
                        .then(res=>{
                            setFlag(!flag)
                        })  
                    }
                }
            }
        }
    }


    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={2} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>

            <h1>Team Info</h1>

             <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns2}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    pagination
                    isRowSelectable ={(params) => params.row.isSuperAdmin === false}
                    checkboxSelection
                    onRowClick={(item,ev)=>{
                        //item.row
                        if(!item.row.isSuperAdmin){
                            setError(null)
                            if(selectedId.filter(i=>i===item.row._id).length>0){
                                setSelectedId(selectedId.filter(i=>i!==item.row._id))
                            }else{
                                setSelectedId([...selectedId,item.row._id])
                            }
                        }else{
                            setError("Cannot Block Super Admin")
                        }
                    }}
                />
            </div>
            {
                error&& <Alert className="m-3" severity="error">{error}</Alert>
            }
            <Button variant="contained" className="m-3" onClick={()=>handleBlock(true)}>Block Selected Users</Button>
            <Button variant="contained" className="m-3" onClick={()=>handleBlock(false)}>Unblock Selected Users</Button>
            



             </div>
    </div>
    )
}

const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.email,width:200},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:150},
    { field: 'company', headerName: 'Company',valueGetter:(param)=>param.row.company,width:150},
    { field: 'phone', headerName: 'Phone',valueGetter:(param)=>param.row.phone,width:150},
    { field: 'admin', headerName: 'Admin',valueGetter:(param)=>param.row.isAdmin.toString(),width:150},
    { field: 'blocked', headerName: 'Blocked',valueGetter:(param)=>param.row.blocked.toString(),width:150},
    // { field: 'package', headerName: 'Package',valueGetter:(param)=>param.row.package.package.name,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}
const mapDispatchToProps = (dispatch)=>{    
    return {
        setLoading:value=>dispatch(setLoading(value))
    }
}
export default  connect(mapStateToProps,mapDispatchToProps)(Team)
