import React from 'react'
import "./Conversations.scss"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Dashhead from '../Dashhead';
import axios from 'axios'
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import {setLoading} from '../redux/loading/loadingActions'
function Conversations(props) {
    console.log(props.user)
    const [display,setDisplay]=React.useState(false)
    const [data,setData]=React.useState([])
    React.useEffect(()=>{
        props.setLoading(true)
        axios.post(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getCompanyRecruiters`,{companyId:props.user.userInfo.companyId},{headers:{token:props.user.user}})
        .then(res=>{
            console.log(res)
            props.setLoading(false)
            let arr = res.data.result.map((item,index)=>({...item,id:index+1}))
            setData(arr)
        })
    },[])
    // const [conversations,setConversations]=React.useState([])
    // let newarray =[]
    // React.useEffect(()=>{
    //     axios.get(`${process.env.REACT_APP_DEVELOPMENT}/api/admin/getAllConversations`,{headers:{token:props.user.user}})
    //     .then(res=>{
    //         console.log(res)
    //         //iterate over the array twice to check if to and from are similar to the swap of from and to i.e.e gather two sided messages
    //         for(let i =0;i<=res.data.length;i++){
    //             let set1 = res.data[i];
    //             for(let j=res.data.length-1;j>i;j--){
    //                 let set2 = res.data[j]
    //                 //swap check
    //                 if(set1._id.from === set2._id.to && set1._id.to === set2._id.from){
    //                     console.log("same",set1)
    //                     let newMessageArray= [...set1.messages,...set2.messages]
    //                     let newObj = {to:set1._id.to,from:set1._id.from,fromUser:set1._id.fromUser,toUser:set1._id.toUser,messages:newMessageArray}
    //                     newarray.push(newObj)
    //                 }
    //             }
    //             // if(set1){
    //             //     if(newarray.length<=0 || newarray[newarray.length-1].to !== set1._id.to || newarray[newarray.length-1].to !== set1._id.from){ //newarray.filter(item=>item.to !== set1._id.to || item.to!==set1._id.from).length>0
    //             //         newarray.push({to:set1._id.to,from:set1._id.from,newMessageArray:set1.messages})
    //             //     }
    //             // }
                
    //         }
    //         //create arrray of ids to check if just recruiter has created the conv

    //         let arrayofid = []
    //         newarray.map(item=>{
    //             arrayofid.push(item.to)
    //             arrayofid.push(item.from)
    //         })
    //         console.log(arrayofid)

    //         //store the messages of just recruiter where conv happened just one sided
    //         res.data.map(i=>{
    //             if(!arrayofid.includes(i._id.to) || !arrayofid.includes(i._id.from)){
    //                 newarray.push(i)
    //             }
    //         })
    //         console.log(newarray)

    //         //filter the data because to and from is in _id
    //         setConversations(newarray.map(item=>{
    //             if(item._id){
    //                 return {...item._id,messages:item.messages}
    //             }else{
    //                 return item
    //             }
    //         }))

    //         //setConversations(newarray)

    //     })
    // },[])

    //console.log("converstaions is",conversations)
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <Dashhead id={3} display={display} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 dashboard-container" onClick={()=>display&&setDisplay(false)}>
            <span className="iconbutton display-mobile">
            <IconButton  size="large" aria-label="Menu" onClick={()=>setDisplay(true)}>
            <MenuIcon fontSize="inherit" />
             </IconButton>
             </span>


            <h1>View Recruiter Conversations</h1>


            <div style={{ height: '80vh', width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns2}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    pagination
                    checkboxSelection
                    onRowClick={(item,ev)=>{
                        //item.row
                        props.history.push("/conversationselector",item.row)

                    }}
                />
            </div>

             </div>
    </div>
    )
}
const mapStateToProps = ({EventUser})=>{    
    return {
        user:EventUser
    }
}

const columns2 = [
    { field: 'id', headerName: 'ID',width:20},
    { field: 'email', headerName: 'Email',valueGetter:(param)=>param.row.email,width:200},
    { field: 'name', headerName: 'Name',valueGetter:(param)=>param.row.name,width:150},
    { field: 'company', headerName: 'Company',valueGetter:(param)=>param.row.company,width:150},
    { field: 'phone', headerName: 'Phone',valueGetter:(param)=>param.row.phone,width:150},
    { field: 'admin', headerName: 'Admin',valueGetter:(param)=>param.row.isAdmin.toString(),width:150},
    { field: 'blocked', headerName: 'Blocked',valueGetter:(param)=>param.row.blocked.toString(),width:150},
    // { field: 'package', headerName: 'Package',valueGetter:(param)=>param.row.package.package.name,width:150},
    {field:"createdAt",headerName:"Created At",valueGetter:(param)=>moment.parseZone(param.value).local().format("DD/MM/YY"),width:120}


  ];
  const mapDispatchToProps = (dispatch)=>{    
    return {
        setLoading:value=>dispatch(setLoading(value))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Conversations)
